import React from "react";

import HAPDocument from "../../assets/others/pdf1.pdf";

const HAPalpa = () => {
  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <object
        data={HAPDocument}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>HA PALPA</p>
      </object>
    </div>
  );
};

export default HAPalpa;
